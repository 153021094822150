@font-face {
  font-family: Damion;
  src: url("./cssFiles/Damion/Damion-Regular.ttf");
  background-size: cover;
}
:root {
  --header: #0d3968;
  --blue: #0d3968;
  --hover: #092a4f;
}
.bg{
  background: var(--blue);
}
.bg:hover{
  background: var(--hover);
}
.fg{
  color: var(--blue);
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.5, 1.5);
  }
}

.carousel-item {
  height: 300px;
  position: relative;
}
.carousel-item::before{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10%;
  left: 0;
  content: "";
  background: linear-gradient(to top, transparent, rgba(12, 12, 12, 0.6));
}
.carousel-inner{
  min-height: 300px;
}

.carousel-inner .carousel-item > img {
  /*-webkit-animation: zoom 50s;
  animation: zoom 50s;*/
  width: 100%;
  object-fit: cover;
  object-position: center;
}
/* Slider */ 
.slick-slide {
  margin: 0px 10px;
} 


/*imported css files and font family*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
body{
    font-family: 'Poppins', sans-serif; 
    background:#ffffff;
}
@media only screen and (max-width: 998px){
  .carousel-item {
    height: auto;
    position: relative;
  }
  .carousel-inner{
    min-height: auto;
  }
}