.images div{
    display: none;
    border-radius: 10px;
    position: relative;
    flex: 47%;
}
.images img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
}
.images div::before{
    position: absolute;
    content: "";
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, black);
    border-radius: 10px;
}
.images div p{
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    width: 80%;
    margin: auto;
    color: white;
}
.sideImg{
    height: 300px;
}
.partners{
    padding: 5% 0;
}
.partners h1{
    font-family: Oswald, sans-serif !important;
    font-weight: 900;
}
.whyMakaPads{
    background: url("../images/img2.jpeg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}
.whyMakaPads h1,
.whyMakaPads div{
    position: relative;
    z-index: 1;
}
.whyMakaPads::before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,1));
}
.facebook{
    width: 450px;
}
@media only screen and (max-width: 998px){
    .sideImg{
        width: 100%;
        height: auto;
    }
    .facebook{
        width: 320px;
    }
}