.galleryDiv::before{
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
    border-radius: 5px;
    z-index: 1;
}
.galleryDiv{
    position: relative;
    flex: 24%;
    flex-grow: 1;
    border-radius: 5px;
    height: 280px;
    overflow: hidden;
    transition: 2s;
}
.galleryDiv img{
    width: 100%;
    height: 280px;
    object-position: top;
    object-fit: cover;
    border-radius: 5px;
    transition: 2s;
}
.galleryDiv:hover img{
    transform: scale(1.1);
}
.galleryDiv:hover{
    filter: brightness(150%);
}
.galleryDiv div{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 90%;
    margin: auto;
    z-index: 1;
}
.flexed{
    gap: 10px;
}
@media only screen and (max-width: 998px){
    .galleryDiv{
        position: relative;
        flex: 31%;
        flex-grow: 1;
        border-radius: 5px;
        height: 280px;
        overflow: hidden;
        margin-bottom: 10px;
        transition: 2s;
    }
}
.pagination{
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 5% 0;
}
.pagination a{
    padding: 10px;
}
.pagination__link--active{
    color: red;
    font-weight: 900;
}
.pagination__link{
    background: grey;
    color: white;
}
.pagination__link:hover{
    color: greenyellow;
}
.pagination__link--disabled{
    background: red;
    color: red;
}
@media only screen and (max-width: 660px){
    .galleryDiv{
        position: relative;
        flex: 100%;
        flex-grow: 1;
        border-radius: 5px;
        height: auto;
        overflow: hidden;
        transition: 2s;
    }
}