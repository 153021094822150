.title h5{
    font-size: 2.2rem;
    font-weight: 900;
    text-shadow: 2px 2px #034986;
}
.title p{
    font-size: 1.2rem;
    font-weight: 700;
    text-shadow: 2px 2px #034986;
}
@media only screen and (max-width: 998px){
    .title h5{
        font-size: 1.4rem;
        font-weight: 900;
        text-shadow: 2px 2px #034986;
    }
    .title p{
        font-size: 0.9rem;
        font-weight: 700;
        text-shadow: 2px 2px #034986;
    }
}