.socials a{
    padding: 10px;
    border-right: 1px solid rgba(245, 245, 245, 0.1);
    font-size: 15px;
}
#fa-location-dot{
    border: none;
    padding: 10px;
    font-size: 20px;
}
.top{
    border-bottom: 1px solid rgba(245, 245, 245, 0.1);
    padding: 0 5%;
}