.justified{
    text-align: justify;
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px 5px 0 0;
    padding-left: 3%;
    padding-right: 3%;
}
.position{
    margin-top: -120px;
}
.background_about{
    background: url("../images/1572b699-75a7-4b31-8fc6-7f0ab40235c0.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 40vh;
    position: relative;
}
.background_about::before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to top, transparent, rgba(0,0,0,0.8));
}
.background_about h1{
    position: relative;
    top: 20%;
}
.about-side{
    width: 100%;
    height: 45vh;
    object-fit: cover;
    object-position: top;
}
.staff img{
    border-radius: 100%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: top;
}
.background{
    background: url("../images/about/c0d296f8-5e1c-46a1-a8b2-71f94a53fb8b.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}
.background::before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to top, transparent, rgba(1, 38, 26, 0.9));
}
.background .row{
    position: relative;
    z-index: 1;
    color: white;
}
@media only screen and (max-width: 998px){
    .background_about{
        background-position: top;
    }
}