.notFound h1{
    font-weight: 900;
    font-size: 4rem;
}
.notFound{
    padding: 5% 0;
}
.notFound p{
    font-size: 2rem;
}
@media only screen and (max-width: 998px){
    .notFound h1{
        font-weight: 900;
        font-size: 2rem;
    }
    .notFound{
        padding: 2.5% 0;
    }
    .notFound p{
        font-size: 1.2rem;
    }
}